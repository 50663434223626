import React from 'react';
import Layout from '../layouts/ja';
import Success from '../components/pages/success';

const SuccessPage = props => (
  <Layout {...props}>
    <Success />
  </Layout>
);

export default SuccessPage;
